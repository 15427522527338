<template>
  <b-card
    class="priceguideoffline-edit-wrapper"
  >
    <!-- form -->
    <b-form id="priceguideofflineForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品Id"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="priceguideoffline.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品编码(69码)"
            label-for="code"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="code"
              size="sm"
              v-model="priceguideoffline.code"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="priceguideoffline.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="公司类别_批发"
            label-for="kind1"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="kind1"
              size="sm"
              v-model="priceguideoffline.kind1"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="公司类别_大型卖场"
            label-for="kind2"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="kind2"
              size="sm"
              v-model="priceguideoffline.kind2"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="公司类别_连锁超市"
            label-for="kind3"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="kind3"
              size="sm"
              v-model="priceguideoffline.kind3"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="公司类别_中型超市"
            label-for="kind4"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="kind4"
              size="sm"
              v-model="priceguideoffline.kind4"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="公司类别_个人用户"
            label-for="kind5"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="kind5"
              size="sm"
              v-model="priceguideoffline.kind5"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="公司类别_便利店"
            label-for="kind6"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="kind6"
              size="sm"
              v-model="priceguideoffline.kind6"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="公司类别_团购"
            label-for="kind7"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="kind7"
              size="sm"
              v-model="priceguideoffline.kind7"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="公司类别_特通"
            label-for="kind8"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="kind8"
              size="sm"
              v-model="priceguideoffline.kind8"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="公司类别_电商"
            label-for="kind9"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="kind9"
              size="sm"
              v-model="priceguideoffline.kind9"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="公司类别_餐饮门店"
            label-for="kind10"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="kind10"
              size="sm"
              v-model="priceguideoffline.kind10"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="priceguideoffline.add_time"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import priceguideofflineStore from './priceguideofflineStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      priceguideoffline: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('priceguideoffline')) store.registerModule('priceguideoffline', priceguideofflineStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('priceguideoffline')) store.unregisterModule('priceguideoffline')
    })

    const edit = function() {
      store.dispatch('priceguideoffline/edit', {id: this.id}).then(res => {
        this.priceguideoffline = res.data.data
      })
    }

    const view = function() {
      store.dispatch('priceguideoffline/view', {id: this.id}).then(res => {
        this.priceguideoffline = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('priceguideoffline/save', this.priceguideoffline).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
